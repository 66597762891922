






    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component({
        name: "NotFound",
        components: {
            notFound: () => import('@/components/NotFoundBanner.vue'),
            protectNow: () => import('@/components/ProtectNow.vue'),
        },
        mixins:[]
    })
    export default class NotFound extends Vue {
        public created():void {
            // on componenet created
        }
        public mounted():void {
            // on componenet mounted
        }
    }
